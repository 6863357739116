<template>
  <div class="preview dark" v-loading="loading" element-loading-text="加载中..." :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in space.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <div class="line" style="padding:24px 0">
            <el-card class="box-card">
              <el-row style="margin-bottom:20px">
                <el-col :span="24">
                  <div class="filter-name">邮箱或手机号</div>
                  <el-select v-model="invitation.userName" multiple :filterable="true" default-first-option ref="multiSelect"
                    placeholder="请输入完整的手机号或邮箱" style="width:100%" :filter-method="filterUser" @change="selectUser">
                    <el-option v-for="item in usersOptions" v-show="filterProgress" :key="item.id" :label="item.nickname"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="margin-bottom:20px">
                <el-col :span="10">
                  <div class="filter-name">选择角色</div>
                  <el-select v-model="invitation.roleName" @change="selectRole" placeholder="选择角色" style="width:100%">
                    <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4"></el-col>
                <el-col :span="10">
                  <div class="filter-name">过期时间</div>
                  <el-date-picker v-model="invitation.expireDate" type="date" placeholder="请选择日期" @change="getDateValue">
                  </el-date-picker>
                </el-col>
              </el-row>
              <el-button type="primary" @click="inviteMember()" v-if="addAuth">邀请</el-button>
            </el-card>
          </div>
          <table class="member-table">
            <colgroup>
              <col>
              <col style="width:450px">
              <col style="width:80px">
            </colgroup>
            <thead>
              <tr>
                <th :colspan="3">
                  <div class="th-left">
                    人员<span>{{ memberList.length }}</span>
                  </div>
                  <!-- <div class="th-right">
                    <div class="member-search">
                      <el-input v-model="searchName"
                                placeholder="根据用户名查询" />
                      <div class="member-search-btn icomoon-search"></div>
                    </div>
                    <b>排序</b>
                    <el-select v-model="sort"
                               placeholder="请选择排序顺序">
                      <el-option v-for="item in sortOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </div> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(n, i) in memberList" :key="i">
                <td>
                  <div class="member-info">
                    <img :src="n.head || defaultHead" alt="">
                    <h4>{{ n.userName }}<span v-if="n.status" :style="{ 'background': n.statusColor }">{{ n.status
                    }}</span></h4>
                    <p>授权于 {{ n.createDate }}</p>
                    <p v-if="!n.isSelf">来源于 <a v-if="n.roleDisabled" href="#" @click="jumpSource(n.sourceId)">{{n.sourceName}}</a>
                      <span v-else style="color: #b3bfc7">{{n.sourceName}}</span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="member-opt">
                    <el-select v-model="n.roleName" class="member-opt-f"
                      :class="{ 'disabled': !isRoleDisabled || n.isCurSpace == 0 || n.isInPrj == 0 || n.isDisabled }"
                      @change="((val) => { updateRole(val, n) })"
                      :disabled="!isRoleDisabled || n.isCurSpace == 0 || n.isInPrj == 0 || n.isDisabled">
                      <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                    <el-date-picker v-model="n.expireDate" type="date" placeholder="请选择日期" class="member-opt-f"
                      :class="{ 'disabled': !isExpireDisabled || n.isCurSpace == 0 || n.isInPrj == 0 || n.isDisabled }"
                      @change="((val) => { updateExpireDate(val, n) })" style="width:auto;flex:1;margin-right:10px"
                      :disabled="!isExpireDisabled || n.isCurSpace == 0 || n.isInPrj == 0 || n.isDisabled">
                    </el-date-picker>
                  </div>
                </td>
                <td v-if="delAuth && (n.isCurSpace == 1 || n.isInPrj == 1) && n.roleId != '1' && (!n.isDisabled || n.isDelSelf)">
                  <el-button type="danger" class="icomoon-bin" @click="deleteMember(n)"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
import _ from 'underscore';
import { btnStatus, prjBtnStatus, jumpRouteSetup } from 'common/authBtn';
export default {
  name: 'MemberMgt',
  setup (props, { emit }) {
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $message = getCurrentInstance().appContext.config.globalProperties.$message;
    const $store = useStore();
    let usersOptions = ref([]), memberList = ref([]), loading = ref(true);
    const defaultHead = require('assets/images/frame/default-head.svg');
    let roleList = ref([
      {
        id: 1,
        name: '拥有者'
      }, {
        id: 2,
        name: '管理员'
      },
      {
        id: 3,
        name: '配置人员'
      }, {
        id: 4,
        name: '开发人员'
      }, {
        id: 5,
        name: '游客'
      }
    ]), space = ref({}), selectedUserIds = ref([]), selectedRole = ref(-1), searchName = ref('');
    let addAuth = ref(false), editAuth = ref(false), delAuth = ref(false),
      isRoleDisabled = ref(false), isExpireDisabled = ref(false), filterProgress = ref(false);
    let invitation = ref({
      userId: "",
      userName: "",
      roleId: -1,
      roleName: "",
      expireDate: "",
      icon: ""
    });

    let multiSelect = ref(null);
    //权限校验
    const filterRoleList = (item, callback) => {
      if (item.type == 1) {
        btnStatus(space.value.id, $store, (result) => {
          addAuth.value = result.addAuth;
          editAuth.value = result.editAuth;
          delAuth.value = result.delAuth;
          isRoleDisabled.value = result.isRoleDisabled;
          isExpireDisabled.value = result.isExpireDisabled;
          roleList.value = result.roleList;
          if (callback)
            callback();
        });
      } else if (item.type == 2) {
        prjBtnStatus(space.value.id, $store, (result) => {
          addAuth.value = result.addAuth;
          editAuth.value = result.editAuth;
          delAuth.value = result.delAuth;
          isRoleDisabled.value = result.isRoleDisabled;
          isExpireDisabled.value = result.isExpireDisabled;
          roleList.value = result.roleList;
          if (callback)
            callback();
        });
      }
    };

    const getDetail = () => {
      let spOrPrj = $store.state.Global.spOrPrj;
      if (!spOrPrj) {
        $api.sp.getCurrentModuleType().then((data) => {
          if ('space' == data) {
            $api.sp.getDetailById().then((res) => {
              if (res) {
                space.value = res;
                filterRoleList(res, () => {
                  getAllMember();
                });
              }
            })
          } else if ('project' == data) {
            $api.sp.getPrjDetailById().then((res) => {
              if (res) {
                space.value = res;
                filterRoleList(res, () => {
                  getAllMember();
                });
              }
            })
          }
        })
      } else {
        if ('space' == spOrPrj) {
          $api.sp.getDetailById().then((res) => {
            if (res) {
              space.value = res;
              filterRoleList(res, () => {
                getAllMember();
              });
            }
          })
        } else if ('project' == spOrPrj) {
          $api.sp.getPrjDetailById().then((res) => {
            if (res) {
              space.value = res;
              filterRoleList(res, () => {
                getAllMember();
              });
            }
          })
        }
      }

    };

    const getAllMember = () => {
      memberList.value = [];
      if (space.value.type == 1) {
        if (!$store.state.currentParam.memberListObj[space.value.id]) {
          $api.sp.getAllMembers({ spaceId: space.value.id }).then(data => {
            memberList.value = data;
            dealMemberList();
          })
        } else {
          memberList.value = $store.state.currentParam.memberListObj[space.value.id] ? $store.state.currentParam.memberListObj[space.value.id] : [];
          dealMemberList();
        }
      } else {
        if (!$store.state.currentParam.prjMemberListObj[space.value.id]) {
          $api.sp.getAllPrjMembers({ prjId: space.value.id }).then(data => {
            memberList.value = data;
            dealMemberList();
          })
        } else {
          memberList.value = $store.state.currentParam.prjMemberListObj[space.value.id] ? $store.state.currentParam.prjMemberListObj[space.value.id] : [];
          dealMemberList();
        }
      }
    };

    const dealMemberList = () => {
      let userId = $store.state.user.userInfo.id;
      let member = memberList.value.filter(v => { return v.userId === userId });
      _.each(memberList.value, (v) => {
        //判断是否是当前成员
        if(v.type == 1) {
          if(v.spaceId != space.value.id) {
            v.isSelf = false;
            let source = _.find(space.value.routes, r => {return r.id == v.spaceId});
            if(source) {
              v.sourceId = v.spaceId;
              v.sourceName = source.name;
              v.roleDisabled = source.disable;
            }
          }else{
            v.isSelf = true;
          }
        }else if(v.type == 2){
          if(!v.prjId) {
            v.isSelf = false;
            let source = _.find(space.value.routes, r => {return r.id == v.spaceId});
            if(source) {
              v.sourceId = v.spaceId;
              v.sourceName = source.name;
              v.roleDisabled = source.disable;
            }
          }else{
            v.isSelf = true;
          }
        }

        //修改权限
        v.isDisabled = false;

        if(member && member.length > 0 && parseInt(member[0].roleId) > parseInt(v.roleId)) {
          v.isDisabled = true;
        } 

        let role = _.filter(roleList.value, (u) => {
          return u.id == v.roleId;
        })
        if (role && role.length > 0) {
          v.roleName = role[0].name;
          if (v.roleId == '1') {
            v.isDisabled = true;
          } else if(isRoleDisabled.value){
            v.isDisabled = false;
          }
        } else if (v.roleId == '1') {
          v.roleName = '拥有者';
          v.isDisabled = true;
        }

        v.status = "";
        v.statusColor = "";
        v.isDelSelf = false;
        if (v.userId == userId) {
          v.status = "本人";
          v.statusColor = "rgba(5, 255, 160, .4)";
          v.isDelSelf = true;
          v.isDisabled = true;
        }
      })
      loading.value = false;
    };

    const filterUser = (data) => {
      if(data.indexOf("@") == -1 && data.length == 11) {
        $api.auth.getFilterUserWithoutPage({
          key: data,
          type: 1
        }).then(res => {
          usersOptions.value = res;
          filterProgress.value = true;
        })
        
      }else if(data.indexOf("@") != -1) {
        $api.auth.getFilterUserWithoutPage({
          key: data,
          type: 2
        }).then(res => {
          usersOptions.value = res;
          filterProgress.value = true;
        })
      } else {
        usersOptions.value = [];
        filterProgress.value = false;
      }
    };

    const selectUser = (val) => {
      selectedUserIds.value = val;
      let time = setTimeout(() => {
         multiSelect.value.blur();
         clearTimeout(time);
      }, 50)

    };

    const jumpSpace = (n) => {
      if (space.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          getDetail();
        })
      }
    };

    const selectRole = (val) => {
      if (val) {
        selectedRole.value = val;
      }
    };

    const getDateValue = (val) => {
      invitation.value.expireDate = val.getFullYear() + '-' + ((val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1))
        + '-' + (val.getDate() < 10 ? ('0' + val.getDate()) : val.getDate())
    };

    const updateRole = (value, item) => {
      if (editAuth.value) {
        $api.sp.validateCurrentUser({
          userIds: [item.userId],
          spaceId: space.value.type == 1 ? space.value.pid : space.value.spaceId,
          roleId: value,
        }).then((res) => {
          if (res.length > 0) {
            initData();
            updateStoreMember();
            let result = '';
            res.map(v => {
              let users = usersOptions.value.filter(u => { return u.id === v.userId });
              if (users && users.length > 0) {
                result += result + users[0].nickname + ",";
              }
            })
            result = result.slice(0, result.length - 1);
            return $message({
              type: 'error',
              message: '用户' + result + '必须邀请大于等于该用户的空间角色'
            });
          } else {
            let args = {
              id: item.id,
              roleId: value,
              userId: item.userId,
              spaceId: item.spaceId
            }
            if (item.isCurSpace == 1) {
              $api.sp.changeRole(args).then((res) => {
                if (res) {
                  $message({
                    type: 'success',
                    message: '操作成功'
                  })
                  initData();
                  updateStoreMember();
                }
              })
            } else {
              $api.sp.changePrjRole(args).then((res) => {
                if (res) {
                  $message({
                    type: 'success',
                    message: '操作成功'
                  })
                  initData();
                  updateStorPrjMember();
                }
              })
            }
          }
        })
      }
    };

    const updateExpireDate = (val, item) => {
      if (editAuth.value) {
        let args = {
          id: item.id,
        }
        if (val)
          args.expireDate = val.getFullYear() + '-' + ((val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1)) +
            '-' + ((val.getDate()) < 10 ? '0' + val.getDate() : val.getDate())
        else
          args.expireDate = null;
        if (item.type == 1) {
          $api.sp.changeExpireTime(args).then((res) => {
            if (res) {
              $message({
                type: 'success',
                message: '操作成功'
              })
              initData();
              updateStoreMember();
            }
          })
        } else if (item.type == 2) {
          $api.sp.changePrjExpireTime(args).then((res) => {
            if (res) {
              $message({
                type: 'success',
                message: '操作成功'
              })
              initData();
              updateStorPrjMember();
            }
          })
        }

      }
    };

    const inviteMember = () => {
      if (selectedUserIds.value.length == 0) {
        $message.error('请先选择用户');
        return;
      }
      $api.sp.validateCurrentUser({
        userIds: selectedUserIds.value,
        spaceId: space.value.type == 1 ? space.value.pid : space.value.spaceId,
        roleId: selectedRole.value,
      }).then((res) => {
        if (res.length > 0) {
          initData();
          updateStoreMember();
          let result = '';
          res.map(v => {
            let users = usersOptions.value.filter(u => { return u.id === v.userId });
            if (users && users.length > 0) {
              result += result + users[0].nickname + ",";
            }
          })
          result = result.slice(0, result.length - 1);
          return $message({
            type: 'error',
            message: '用户' + result + '必须邀请大于等于该用户的空间角色'
          });
        } else {
          if (space.value.type == 1) {
            let args = {
              spaceId: space.value.id,
              userId: selectedUserIds.value,
              roleId: selectedRole.value,
              expireDate: invitation.value.expireDate
            }
            $api.sp.addMember(args).then((res) => {
              if (res.length == 0) {
                $message({
                  type: 'success',
                  message: '邀请成功'
                });
                initData();
                space.value.type == 1 ? updateStoreMember() : updateStorPrjMember();
              } else {
                let result = '';
                res.map(v => {
                  let users = usersOptions.value.filter(u => { return u.id === v });
                  if (users && users.length > 0) {
                    result += result + users[0].nickname + ",";
                  }
                })
                result = result.slice(0, result.length - 1);
                $message({
                  type: 'info',
                  message: result + '已经邀请过，请勿重复邀请'
                })
              }
            })
          } else {
            let args = {
              prjId: space.value.id,
              userId: selectedUserIds.value,
              roleId: selectedRole.value,
              expireDate: invitation.value.expireDate
            }
            $api.sp.addPrjMember(args).then((res) => {
              if (res.length == 0) {
                $message({
                  type: 'success',
                  message: '邀请成功'
                });
                initData();
                updateStorPrjMember();
              } else {
                let result = '';
                res.map(v => {
                  let users = usersOptions.value.filter(u => { return u.id === v });
                  if (users && users.length > 0) {
                    result += result + users[0].nickname + ",";
                  }
                })
                result = result.slice(0, result.length - 1);
                $message({
                  type: 'info',
                  message: result + '已经邀请过，请勿重复邀请'
                })
              }
            })
          }
        }
      })
    };

    const updateStoreMember = () => {
      $store.state.currentParam.memberListObj[space.value.id] = [];
      updateFn();
    };

    const updateFn = () => {
      filterRoleList(space.value, () => {
          getAllMember();
        });
    };

    const updateStorPrjMember = () => {
      $store.state.currentParam.prjMemberListObj[space.value.id] = [];
      updateFn();
    };

    const deleteMember = (item) => {
      if (item.type == 1) {
        $api.sp.deleteMember({ id: item.id, spaceId: item.spaceId }).then((res) => {
          $message({
            type: 'success',
            message: '操作成功'
          });
          initData();
          updateStoreMember();
        })
      } else if (item.type == 2) {
        $api.sp.deletePrjMember({ id: item.id, prjId: item.prjId }).then((res) => {
          $message({
            type: 'success',
            message: '操作成功'
          });
          initData();
          updateStorPrjMember();
        })
      }
    };

    const initData = () => {
      invitation.value = {
        userId: "",
        userName: "",
        roleId: -1,
        roleName: "",
        expireDate: "",
        icon: ""
      };
      selectedUserIds.value = [];
      selectedRole.value = -1;
    };

    const jumpSource = (sourceId) => {
      let route = _.find(space.value.routes, v => {
        return v.id == sourceId;
      })
      if(route) {
        jumpSpace(route);
      }
    };

    onMounted(() => {
      getDetail();
      // getAllUsers();
    })

    return {
      multiSelect,
      loading,
      defaultHead,
      usersOptions,
      space,
      searchName,
      memberList,
      addAuth,
      editAuth,
      delAuth,
      isRoleDisabled,
      isExpireDisabled,
      invitation,
      roleList,
      filterProgress,
      jumpSpace,
      filterUser,
      selectUser,
      selectRole,
      getDateValue,
      updateRole,
      updateExpireDate,
      inviteMember,
      deleteMember,
      jumpSource
    }
  },

}
</script>

<style lang="scss" scoped>
.filter-name {
  width: 100%;
  float: left;
  line-height: 1;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 8px 0;
  font-size: 14px;
}

.member-table {
  width: 100%;
  float: left;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;

  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    th {
      background: rgba(255, 255, 255, 0.03);
      padding: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      .th-left {
        float: left;
        line-height: 40px;
        font-weight: bold;
        font-size: 16px;
        padding: 0 10px;

        span {
          height: 20px;
          padding: 0 10px;
          border-radius: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          background: rgba(30, 170, 255, 0.4);
          font-weight: normal;
        }
      }

      .th-right {
        float: right;

        .member-search {
          width: 200px;
          position: relative;
          float: left;
          margin-right: 20px;

          .member-search-btn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            color: rgba(255, 255, 255, 0.4);
            font-weight: normal;

            &:hover {
              color: rgba(30, 170, 255, 1);
            }

            &:active {
              transform: translateY(1px);
            }
          }
        }

        b {
          line-height: 40px;
          float: left;
          font-size: 14px;
          padding: 0 6px;
        }
      }
    }

    td {
      padding: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .member-info {
      width: 100%;
      padding: 10px 10px 10px 60px;

      img {
        width: 40px;
        height: 40px;
        background: #ddd;
        border-radius: 50%;
        float: left;
        margin: 0 -50px;
      }

      h4,
      p {
        width: 100%;
        float: left;
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;

        span {
          height: 24px;
          font-size: 14px;
          padding: 0 4px;
          border-radius: 4px;
          background: #999;
          margin: 0 8px;
        }
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.8);

        a {
          color: rgba(0, 130, 255, 1);
          cursor: pointer;

          &:hover {
            color: rgba(30, 170, 255, 1);
          }
        }
      }
    }

    .member-opt {
      width: 100%;
      display: flex;
      flex-direction: row;

      .member-opt-f {
        width: auto;
        flex: 1;
        margin-right: 10px;
      }
    }
  }
}
</style>